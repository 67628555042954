// define a mixin object
import {PROPERTY_EDITORS} from "@/shared/consts";
import {log, getUUID} from "@/shared/utils";

import {getTemplateSchemaSettingsCollectionPropertyByAlias, saveTemplateSchemaSettingsCollectionPropertyByAlias} from "@/shared/dataservice";

export const templateSchemaSettingsCollectionPropertyActionsMixin = {
    data() {
        return {
            templateSchemaSettingsCollectionProperty: {}
        }
    },
    methods: {

        async loadTemplateSchemaSettingsCollectionPropertyByAlias() {
            let result = await getTemplateSchemaSettingsCollectionPropertyByAlias(this.templateSchemaSettingsCollectionID, this.templateSchemaSettingsCollectionPropertyAlias);

            log(["Loaded templatessc property by alias", result.data.data]);

            if (result.status === 200) {
                this.templateSchemaSettingsCollectionProperty = result.data.data;

                return true;
            } else {
                return false;
            }
        },

        async saveTemplateSchemaSettingsCollectionPropertyOptions(property) {
            let result = await saveTemplateSchemaSettingsCollectionPropertyByAlias(this.templateSchemaSettingsCollectionID, this.templateSchemaSettingsCollectionPropertyAlias, {
                configuration: this.getTemplateSchemaSettingsCollectionPropertyConfiguration(property),
                propertyAlias: this.templateSchemaSettingsCollectionPropertyAlias
            });

            log(["Saved templatessc property by alias", result]);

            if (result.status === 200) {
                return true;
            } else {
                return false;
            }
        },

        getTemplateSchemaSettingsCollectionPropertyConfiguration(property, ensureOptionsPresent = false) {
            if(Object.prototype.hasOwnProperty.call(property, 'configuration')){

                if(ensureOptionsPresent){
                    if(!Object.prototype.hasOwnProperty.call(property.configuration, 'data')){
                        property.configuration.data = {};
                    }
                    if(!Object.prototype.hasOwnProperty.call(property.configuration.data, 'options')){
                        property.configuration.data.options = [];
                    }
                }

                return property.configuration;
            }
            else
            {
                return {};
            }
        },

        addNewTemplateSchemaSettingsCollectionPropertyOption(property, option) {
            if(!Object.prototype.hasOwnProperty.call(property, 'configuration')){
                property.configuration = {};
            }
            if(!Object.prototype.hasOwnProperty.call(property.configuration, 'data')){
                property.configuration.data = {};
            }
            if(!Object.prototype.hasOwnProperty.call(property.configuration.data, 'options')){
                property.configuration.data.options = [];
            }

            option.id = getUUID();
            property.configuration.data.options.push(option);
        },

        deleteTemplateSchemaSettingsCollectionPropertyOption(property, id) {
            if(Object.prototype.hasOwnProperty.call(property, 'configuration') && Object.prototype.hasOwnProperty.call(property.configuration, 'data') && Object.prototype.hasOwnProperty.call(property.configuration.data, 'options')){
                let targetIndex = property.configuration.data.options.findIndex(option => option.id === id);
                //log(What is my );
                if(targetIndex > -1)
                {
                    property.configuration.data.options.splice(targetIndex, 1);
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        },

        updateTemplateSchemaSettingsCollectionPropertyOption(property, updatedOption) {
            if(Object.prototype.hasOwnProperty.call(property, 'configuration') && Object.prototype.hasOwnProperty.call(property.configuration, 'data') && Object.prototype.hasOwnProperty.call(property.configuration.data, 'options')){
                let targetIndex = property.configuration.data.options.findIndex(option => option.id === updatedOption.id);
                //log(What is my );
                if(targetIndex > -1)
                {
                    property.configuration.data.options[targetIndex] = updatedOption;
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        },

        /**
         * UTILS
         */
        templateSchemaSettingsCollectionPropertyEditorTypeHasEditableOptions(rowEditorType) {
            //log(["Filtering by: ", rowEditorType, PROPERTY_EDITORS.find(x => x.editorType === rowEditorType).hasOwnProperty('optionsEditComponent')]);
            return Object.prototype.hasOwnProperty.call(PROPERTY_EDITORS.find(x => x.editorType === rowEditorType), 'optionsEditComponent');
        },
        getTemplateSchemaSettingsCollectionPropertyEditorComponentName(rowEditorType){
            return this.templateSchemaSettingsCollectionPropertyEditorTypeHasEditableOptions(rowEditorType) ? PROPERTY_EDITORS.find(x => x.editorType === rowEditorType).optionsEditComponent : false;
        },
        getEditableTemplateSchemaSettingsCollectionProperties(collectionProperties){
            log(["GETTING EDITABLE TEMPLATE SCHEMA SETTINGS", collectionProperties]);

            let result = {}, key;

            for (key in collectionProperties) {
                if (this.templateSchemaSettingsCollectionPropertyEditorTypeHasEditableOptions(collectionProperties[key].editorType)) {
                    result[key] = collectionProperties[key];
                }
            }

            let sortedResult = Object.entries(result).sort((a, b) => {  return a[1].order - b[1].order; });

            let objSortedResult = {}
            sortedResult.forEach(function(item){
                objSortedResult[item[0]]=item[1]
            })

            log(["Done", objSortedResult]);

            return objSortedResult;
        }
    },
}